// DEPS
import React, { useRef } from "react";
import { T } from "@transifex/react";

// TYPES
import { SubjectUnitCard } from "p6m-subjects";

// COMPONENTS
import CardEntry from "../../basic/cardEntry/CardEntry";

// STYLES
import {
    CardList,
    MoreCardsText,
    NoCardContainer,
    NoCardText,
    ShowLink,
    ShowMore,
} from "../../connected/librarySubjectEntry/styles";

interface SubjectCardsProps {
    cards: SubjectUnitCard[];
    subjectId: string;
    refreshCardList: () => void;
    onSelectSubject: () => void;
}

export const SubjectCards = ({ subjectId, refreshCardList, onSelectSubject, cards }: SubjectCardsProps) => {
    const CardListRef = useRef(null);

    return (
        <CardList ref={CardListRef}>
            {cards.length === 0 && (
                <NoCardContainer>
                    <NoCardText>
                        <T
                            _str="There are no cards matching your search options."
                            _tags="message,library,content"
                        />
                    </NoCardText>
                </NoCardContainer>
            )}
            {cards.map((card, index) => {
                const {
                    cardContent: { question: cardQuestion, answer: cardAnswer, swappable: cardIsSwappable },
                    cardIdToOwner: { id: cardId, ownerId },
                    normal: { active: normalIsActive, wasActive: normalWasActive, phase: normalPhaseOfCard },
                    opposite: { active: oppositeIsActive, wasActive: oppositeWasActive, phase: oppositePhaseOfCard },
                } = card;

                return (
                    <CardEntry
                        key={`card_${index}_${cardId}`}
                        cardId={cardId}
                        cardOwnerId={ownerId}
                        isSearchResult={true}
                        isSelected={false}
                        normalIsActive={normalIsActive}
                        normalWasActive={normalWasActive}
                        oppositeIsActive={oppositeIsActive}
                        oppositeWasActive={oppositeWasActive}
                        normalPhase={normalPhaseOfCard}
                        oppositePhase={oppositePhaseOfCard}
                        contentQuestion={cardQuestion}
                        contentAnswer={cardAnswer}
                        cardIsSwappable={cardIsSwappable}
                        toggleCard={() => {}}
                        unitName=""
                        subjectId={subjectId}
                        refreshCardList={refreshCardList}
                    />
                );
            })}
            {cards.length >= 5 && (
                <ShowMore onClick={onSelectSubject}>
                    <MoreCardsText>
                        <T
                            _str="more cards available"
                            _tags="library,list,tooltip"
                        />
                    </MoreCardsText>
                    <ShowLink>
                        <T
                            _str="show"
                            _tags="link,button"
                        />
                    </ShowLink>
                </ShowMore>
            )}
        </CardList>
    );
};
