// REACT
import React, { FunctionComponent, useMemo, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { selectors } from "../../../../../redux/learning/learningSlice";
import { selectors as userSelectors } from "../../../../../redux/user/userSlice";
import { selectors as subjectSelectors } from "../../../../../redux/subjects/subjectsSlice";
import { actions as goalsActions, selectors as goalsSelectors } from "../../../../../redux/goals/goalsSlice";
import { actions as modalActions } from "../../../../../redux/modal/modalSlice";

// TYPES
import { Props as ModalProps } from "../../../../basic/modal/Modal";

// COMPONENTS
import Component from "./Done";

// UTILS
import { ThemeContext } from "styled-components";
import { GlobalModalView } from "../../../../../helpers/Modal";

const phase6Url = process.env.REACT_APP_WEBSITE_FULL_DOMAIN || "https://www.phase-6.de/";

type Props = Partial<ModalProps>;

export const Done: FunctionComponent<Props> = (props) => {
    const practiced = useSelector(selectors.filteredItems({ type: "practice" }));
    const subjectId = useSelector(selectors.subjectId);
    const trialTime = useSelector(subjectSelectors.getTrialTime(subjectId));
    const userId = useSelector(userSelectors.userId) || "";
    const jossoId = useSelector(userSelectors.jossoId);
    const isChild = useSelector(userSelectors.isChild);
    const theme = useContext(ThemeContext);
    const history = useHistory();
    const hasGrammarTutorPromotionEnabled = useSelector(userSelectors.hasGrammarTutorPromotionEnabled);
    const achievements = useSelector(goalsSelectors.goals);

    const dispatch = useDispatch();

    const subjectIsTrial: boolean = trialTime > 0;

    const total: number = practiced.length;

    const atFirst: number = useMemo(() => {
        return practiced.filter(({ submitCount }) => submitCount === 1).length;
    }, [practiced]);

    const userStars: number = 3;

    const backgroundColor = theme.colors.backgroundContrast;

    const onGoHome = useCallback(() => {
        dispatch(goalsActions.fetchGoals());
        // only show modal to users who have not yet finished a GT topic
        if (hasGrammarTutorPromotionEnabled && !achievements?.goals.finished1GtTopic) {
            dispatch(modalActions.setModalView(GlobalModalView.PromoteGrammarTutor));
        }
        history.push("/home");
    }, [dispatch, history, hasGrammarTutorPromotionEnabled]);

    const onBuyContent = useCallback(() => {
        const url = new URL(`/shop/login/?JossoSessionID=${jossoId}&ArticleUUID=${subjectId}`, phase6Url);
        window.open(url.href, "_blank");
        onGoHome();
    }, [subjectId, jossoId, onGoHome]);

    return (
        <Component
            total={total}
            atFirst={atFirst}
            backgroundColor={backgroundColor}
            userId={userId}
            userStars={userStars}
            subjectId={subjectId}
            subjectIsTrial={subjectIsTrial}
            isChild={isChild}
            onGoHome={onGoHome}
            onBuyContent={onBuyContent}
            {...props}
        />
    );
};
