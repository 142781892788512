// LIBRARIES
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Redirect, useHistory, useParams } from "react-router-dom";
import { debounce, cloneDeep } from "lodash";
import { useT } from "@transifex/react";
import { ampli } from "../../ampli";

// REDUX
import { actions as subjectsActions, derived as subjectsDerived } from "../../redux/subjects/subjectsSlice";
import { actions as userActions, selectors as userSelectors } from "../../redux/user/userSlice";
import { actions as responseActions } from "../../redux/response/responseSlice";
import { actions as appStatusActions } from "../../redux/appStatus/appStatusSlice";
import { actions as modalActions } from "../../redux/modal/modalSlice";

//TYPES
import { DropdownOption, EditableContentType } from "p6m-viewData";
import { EntryChange, IdToOwnerId, SubjectData } from "p6m-subjects";
import { ResponseMessageKeys } from "../../components/connected/response";

// COMPONENTS
import Manage from "./Manage";

// NETWORK
import { postSubject, postUnit } from "../../networking/subjects";

// UTILS
import { ResponseType } from "../../constants/ResponseConstants";
import { defaultUnitOrder } from "../../constants/ContentConstants";
import { GlobalModalView } from "../../helpers/Modal";
import { getNextUnitOrder, getDefaultUnitId } from "../../helpers/Units";
import { getValidSubjectOptionsFromSubjects, generateSubjectTemplate } from "../../helpers/Subjects";
import { generateUuid } from "../../helpers/Id";

// HOOKS
import { useAppVersionCheck } from "../../hooks/useAppVersionCheck";

const ManageWrapper: React.FC = () => {
    const t = useT();
    const history = useHistory();
    const dispatch = useDispatch();
    const { subjectId, searchText } = useParams();

    const user = useSelector(userSelectors.user);
    const userMetadata = useSelector(userSelectors.userMetadata);
    const userPreferences = useSelector(userSelectors.userPreferences);
    const isTeacher = useSelector(userSelectors.isTeacher);
    const allValidSubjects = useSelector(subjectsDerived.nonExpiredSubjects);
    const { phases } = useSelector(userSelectors.userPreferences);

    const [subjectsWereFetched, setSubjectsWereFetched] = useState<boolean>(false);

    const hasFirstPractice = isTeacher || userMetadata.hasFirstPractice;
    const isLibraryAccessDisabled = userPreferences.parentSettings
        ? !userPreferences.parentSettings.libraryAccess
        : false;

    const T_defaultUnit = t("General", {});
    const t_libraryAccessBlockedByAdmin = t("The group admin has blocked the access to the library.", {
        _tags: "response,error,library",
    });

    const setResponse = useCallback(
        (type: ResponseType, text: string) => {
            dispatch(responseActions.showResponse({ type: type, text: [text] }));
        },
        [dispatch]
    );

    useAppVersionCheck();

    useEffect(() => {
        if (!hasFirstPractice || isLibraryAccessDisabled) {
            if (isLibraryAccessDisabled) {
                setResponse("ERROR", t_libraryAccessBlockedByAdmin);
            }
            history.push("/home");
        }
    }, [hasFirstPractice, isLibraryAccessDisabled, history, setResponse, t_libraryAccessBlockedByAdmin]);

    useEffect(() => {
        dispatch(subjectsActions.loadUserSubjects({ withUnits: true }));
        dispatch(subjectsActions.loadFamilySubjects());
    }, [dispatch]);

    const loadSubjects = useCallback(async () => {
        dispatch(appStatusActions.setLoading(true));
        dispatch(subjectsActions.loadUserSubjects({ withUnits: true }));
        setSubjectsWereFetched(true);
        dispatch(appStatusActions.setLoading(false));
    }, [dispatch]);

    useEffect(() => {
        if (!allValidSubjects?.length && !subjectsWereFetched) {
            loadSubjects();
        }
    }, [allValidSubjects, subjectsWereFetched, loadSubjects]);

    useEffect(() => {
        if (!phases || phases.phaseList.length === 0) {
            dispatch(userActions.refreshPreferencesData(user.userDnsId || ""));
        }
    }, [dispatch, phases, user]);

    const [subjectOptions, setSubjectOptions] = useState<DropdownOption[]>([]);

    useEffect(() => {
        setSubjectOptions(getValidSubjectOptionsFromSubjects(allValidSubjects, "" + user.userDnsId));
    }, [allValidSubjects, setSubjectOptions, user.userDnsId]);

    // debounce switching subject to prevent a back and forth switching when moving cards
    const onSubjectSelect = useCallback(
        debounce((passedSubjectId: string | undefined, passedSearchValue: string) => {
            if (passedSubjectId) {
                let path: string = "/manage/" + passedSubjectId;
                if (passedSearchValue.length > 0) path += "/" + passedSearchValue;
                history.push(path);
            } else {
                // use generatePath to remove params
                history.push({ pathname: generatePath("/manage") });
            }
        }, 500),
        [history]
    );

    const openDeleteModal = useCallback(
        (deleteData: {
            contentType: EditableContentType;
            contentNames: string[];
            itemIds: IdToOwnerId[];
            deletableCardCount: number;
            selectedSubjectId?: string;
            selectedCardCount?: number;
        }) => {
            dispatch(modalActions.setData(deleteData));
            dispatch(modalActions.setModalView(GlobalModalView.DeleteContent));
        },
        [dispatch]
    );

    const onDeleteSubject = useCallback(
        (deleteSubjectId: string) => {
            const subjectToDelete = allValidSubjects.find(
                (subject) => subject.subjectMetadata.subjectIdToOwner.id === deleteSubjectId
            );
            if (subjectToDelete) {
                openDeleteModal({
                    contentType: "subjects",
                    contentNames: [subjectToDelete.subjectContent.name],
                    itemIds: [subjectToDelete.subjectMetadata.subjectIdToOwner],
                    deletableCardCount: subjectToDelete.groupedCardCount?.cardCounts.LIBRARY.cardCount || 0,
                    selectedSubjectId: subjectId,
                });
            }
        },
        [openDeleteModal, subjectId, allValidSubjects]
    );

    const changeSubject = useCallback(
        (index: number, name: string) => {
            const newSubjectOptions = cloneDeep(subjectOptions);
            newSubjectOptions[index].title = name;
            setSubjectOptions(newSubjectOptions);
        },
        [subjectOptions]
    );

    const resetSubjects = useCallback(() => {
        setSubjectOptions(getValidSubjectOptionsFromSubjects(allValidSubjects, "" + user.userDnsId));
    }, [allValidSubjects, user.userDnsId]);

    const saveUnitEdit = async (unitId: string, unitName: string) => {
        const subject = allValidSubjects.find((subject) => subject.subjectMetadata.subjectIdToOwner.id === subjectId);
        if (subject) {
            const unit = subject.units?.find((unit) => unit.unitId.id === unitId);
            if (unit) {
                const unitContent = {
                    ...unit.unitContent,
                    name: unitName,
                };
                await postUnit(unit.unitId.ownerId, unitId, unitContent);
            } else if (user.userDnsId) {
                // is unit add
                const newUnitId = generateUuid();
                let order = getNextUnitOrder(subject.units);

                const unitContent = {
                    active: null,
                    modificationDate: null,
                    name: unitName,
                    order,
                    ownerId: user.userDnsId,
                    subjectIdToOwner: {
                        id: subjectId,
                        ownerId: user.userDnsId,
                    },
                };
                await postUnit(user.userDnsId, newUnitId, unitContent);
            }
        }
        // reload units to get update
        dispatch(subjectsActions.loadSubjectUnits(subjectId));
    };

    const deleteUnits = useCallback(
        (changes: EntryChange[]) => {
            const subject = allValidSubjects.find(
                (subject) => subject.subjectMetadata.subjectIdToOwner.id === subjectId
            );
            if (subject) {
                const unitsToDelete: IdToOwnerId[] = [];
                const unitNames: string[] = [];
                let cardAmountToBeDeleted: number = 0;
                changes.forEach((change) => {
                    const unit = subject.units?.find((unit) => unit.unitId.id === change.id);
                    if (unit) {
                        unitsToDelete.push(unit.unitId);
                        unitNames.push(unit.unitContent.name);
                        cardAmountToBeDeleted += unit.cardCount || 0;
                    }
                });
                openDeleteModal({
                    contentType: "units",
                    contentNames: unitNames,
                    itemIds: unitsToDelete,
                    deletableCardCount: cardAmountToBeDeleted,
                    selectedSubjectId: subjectId,
                });
            }
        },
        [openDeleteModal, subjectId, allValidSubjects]
    );

    const saveSubjectEdit = async (changes: EntryChange[]) => {
        let updates: Promise<any>[] = [];
        let subjectsToDelete: SubjectData[] = [];
        changes.forEach((change) => {
            const subject = allValidSubjects.find((sub) => sub.subjectMetadata.subjectIdToOwner.id === change.id);
            if (subject) {
                if (change.deleted) {
                    subjectsToDelete.push(subject);
                } else if (subject.subjectContent.name !== change.name) {
                    updates.push(
                        postSubject(
                            generateSubjectTemplate({
                                subjectId: subject.subjectMetadata.subjectIdToOwner.id,
                                creatorId: subject.subjectMetadata.subjectIdToOwner.ownerId,
                                name: change.name,
                            })
                        )
                    );
                }
            } else if (!change.deleted) {
                // if "deleted" here this subject was newly created but marked for deletion
                // subject not found => is add
                const dnsId = user.userDnsId;
                if (dnsId && change.name) {
                    updates.push(
                        new Promise((resolve) => {
                            const newSubjectId = generateUuid();
                            ampli.clickCreateSubjectA({ app_screen: "library" });
                            dispatch(
                                subjectsActions.complexSubjectsRest({
                                    created: [
                                        generateSubjectTemplate({
                                            subjectId: newSubjectId,
                                            creatorId: dnsId,
                                            name: change.name,
                                            primaryLang: "de",
                                        }),
                                    ],
                                })
                            );
                            // create general unit
                            const newUnitId = getDefaultUnitId(newSubjectId);
                            const unitContent = {
                                active: null,
                                modificationDate: null,
                                name: T_defaultUnit,
                                order: defaultUnitOrder,
                                ownerId: user.userDnsId,
                                subjectIdToOwner: {
                                    id: newSubjectId,
                                    ownerId: user.userDnsId,
                                },
                            };
                            postUnit("" + user.userDnsId, newUnitId, unitContent).finally(() => resolve());
                        })
                    );
                } else {
                    dispatch(
                        responseActions.showResponse({
                            type: "WARNING",
                            responseMessage: ResponseMessageKeys.ERROR_CREATING_SUBJECT,
                        })
                    );
                }
            }
        });
        if (subjectsToDelete.length > 0) {
            const contentNames: string[] = [];
            const itemIds: IdToOwnerId[] = [];
            let cardAmountToBeDeleted = 0;
            subjectsToDelete.forEach((subject) => {
                contentNames.push(subject.subjectContent.name);
                itemIds.push(subject.subjectMetadata.subjectIdToOwner);
                cardAmountToBeDeleted += subject.groupedCardCount?.cardCounts.LIBRARY.cardCount || 0;
            });
            openDeleteModal({
                contentType: "subjects",
                contentNames,
                itemIds,
                deletableCardCount: cardAmountToBeDeleted,
                selectedSubjectId: subjectId,
            });
        }
        await Promise.all(updates);
        // reload subjects to get all updates
        dispatch(subjectsActions.loadUserSubjects({ withUnits: true }));
    };

    const subject = allValidSubjects.find((subject) => subject.subjectMetadata.subjectIdToOwner.id === subjectId);

    if (subject) {
        return (
            <Manage
                user={user}
                isTeacher={isTeacher}
                subjectId={subjectId}
                allUserSubjects={allValidSubjects}
                subject={subject}
                subjectOptions={subjectOptions}
                initialSearchText={searchText}
                onSubjectSelect={onSubjectSelect}
                availablePhases={phases?.phaseList.map((_, i) => i) || []}
                deleteSubject={onDeleteSubject}
                setResponse={setResponse}
                changeSubject={changeSubject}
                resetSubjectEdit={resetSubjects}
                saveSubjectEdit={saveSubjectEdit}
                saveUnitEdit={saveUnitEdit}
                deleteUnits={deleteUnits}
            />
        );
    } else {
        return <Redirect to="/manage" />;
    }
};

export default ManageWrapper;
