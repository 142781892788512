// LIBRARIES
import React from "react";
import { generatePath, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ampli } from "../../../ampli";

//NETWORK
import { deleteCards } from "../../../networking/cards";
import { deleteSubject, deleteUnit } from "../../../networking/subjects";

//REDUX
import { actions as modalActions, selectors as modalSelectors } from "../../../redux/modal/modalSlice";
import { actions as subjectsActions } from "../../../redux/subjects/subjectsSlice";

//TYPES
import { GlobalModalView } from "../../../helpers/Modal";
import { IdToOwnerId } from "p6m-subjects";
import { EditableContentType } from "p6m-viewData";

//COMPONENTS
import DeleteContentModal from "./DeleteContentModal";

const DeleteContentModalWrapper: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        contentType,
        contentNames = [],
        itemIds,
        deletableCardCount,
        selectedSubjectId,
        selectedCardCount,
        refreshCardList,
    }: {
        contentType: EditableContentType;
        contentNames?: string[];
        itemIds: IdToOwnerId[];
        deletableCardCount: number;
        selectedSubjectId?: string;
        selectedCardCount?: number;
        refreshCardList?: () => void;
    } = useSelector(modalSelectors.data);

    const close = (selectedSubjectDeleted?: boolean) => {
        if (selectedSubjectDeleted && history.location.pathname.match(/^\/manage\//)) {
            history.push({ pathname: generatePath("/manage") });
        }
        dispatch(modalActions.setModalView(GlobalModalView.None));
    };

    const deleteContent = async () => {
        if (contentType === "subjects") {
            await Promise.all(itemIds.map((subjectId) => deleteSubject(subjectId)));
            dispatch(subjectsActions.loadLibrarySubjects());
            dispatch(subjectsActions.loadUserSubjects({ withUnits: true }));
        } else if (contentType === "units") {
            await Promise.all(itemIds.map((unitId) => deleteUnit(unitId)));
            dispatch(subjectsActions.loadUserSubjects({ withUnits: true }));
        } else if (contentType === "cards") {
            await deleteCards(itemIds);
            ampli.clickedEditCardsButton({
                cards_number: itemIds.length,
                user_action: "deleteCard",
            });
            dispatch(subjectsActions.loadUserSubjects({ withUnits: true }));
        }

        refreshCardList?.();

        const selectedSubjectDeleted =
            contentType === "subjects" && !!itemIds.find((subject) => subject.id === selectedSubjectId);
        close(selectedSubjectDeleted);
    };

    return (
        <DeleteContentModal
            contentType={contentType}
            names={contentNames}
            deletableCardCount={deletableCardCount}
            selectedCardCount={selectedCardCount}
            deleteContent={deleteContent}
        />
    );
};

export default DeleteContentModalWrapper;
